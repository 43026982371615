<template>
  <div class="about">
    <div class="topcont">
      <div class="topright titles azjgs">
        <div v-if="!user_area">
          区域名称：
          <el-select ref="selectArea" @visible-change="$visibleChange($event, 'selectArea')" filterable v-model="cgxqy" placeholder="全部" clearable @change="cxjgmc">
            <el-option :value="item.id" v-for="(item, index) in cgxqyArr" :key="index" :label="item.name"></el-option>
          </el-select>
        </div>
        <div v-if="!user_org">
          机构名称：
          <el-select ref="selectOrg" @visible-change="$visibleChange($event, 'selectOrg')" class="devSty" filterable v-model="cjgmc" placeholder="全部" clearable @change="cxsbmc">
            <el-option :value="item.id" v-for="(item, index) in cjgmcArr" :key="index" :label="item.name"></el-option>
          </el-select>
        </div>
        <div v-if="processFlag">
          设备名称：
          <el-select ref="selectDev" @visible-change="$visibleChange($event, 'selectDev')" class="devSty" filterable v-model="csbmc" placeholder="全部" clearable @change="cxchange">
            <el-option :value="item.id" v-for="(item, index) in csbmcArr" :key="index" :label="item.name"></el-option>
          </el-select>
        </div>
        <div v-if="typeFlag[0] == 17">
          探 测 器 ：
          <el-select ref="selectTcq" class="devSty" filterable v-model="ctcq" placeholder="全部" multiple collapse-tags clearable>
            <el-option :value="item.name" v-for="(item, index) in ctcqArr" :key="index" :label="item.name"></el-option>
            <el-button style="width: 100%" type="success" size="mini" icon="el-icon-search" @click="ctqchange">确 定</el-button>
          </el-select>
        </div>
        <span v-if="processFlag">
          <el-button type="info" size="mini" icon="el-icon-refresh-right" @click="reserts">重 置</el-button>
        </span>
      </div>
      <div :class="{ topbottom: true, flag17: typeFlag[0] == 17 }" v-if="processFlag">
        <div v-if="typeFlag[0] == 17" :class="{ one, two, three, four, five, six }">
          <div v-for="i in chatNumNew" :key="i.index">
            <div class="fontSty">{{ i.name }}本底值：{{ i.probe ? i.probe : 0 }} CPS; {{ i.probeMS ? '(' + i.probeMS + 'μSv/h)' : '' }} 阈值：[ {{ i.probeThreshold ? i.probeThreshold : 0 }} CPS; {{ i.probeMSThreshold ? '(' + i.probeMSThreshold + 'μSv/h)' : '' }} ]</div>
            <div class="contSty"></div>
          </div>
        </div>
        <div v-else-if="typeFlag[0] == 18" class="twoSty">
          <div v-for="i in chatNumNew" :key="i.index">
            <!-- <div class="fontSty">{{ i.name }} 是否报警：{{ i.alarmTag == 0 ? '否' : '是' }}， 本底值：{{ i.probeBackgroundMs || 0 }}， 剂量率：{{ i.probeMs || 0 }}， 阈值：{{ i.probeThresholdMs || 0 }}， 累计行人数量：{{ i.pedNumber || 0 }}</div> -->
            <div class="fontSty">{{ i.name }} 是否报警：{{ i.alarmTag == 0 ? '否' : '是' }}， 本底值：{{ i.probeBackground || 0 }}， 计数率：{{ i.probeCps || 0 }}， 阈值：{{ i.probeThreshold || 0 }}， 累计行人数量：{{ i.pedNumber || 0 }}</div>
            <div class="contSty"></div>
          </div>
        </div>
        <div class="chartData" v-else>
          <div style="height: 100%">
            <div ref="emainCir" id="emainCir"></div>
          </div>
          <div>
            <div>当前设备: {{ deviceName }}</div>
            <div class="statusSty">
              当前状态: 服务器: <span :style="{ color: serveColor }">{{ serveColor == 'red' ? '未连接' : '已连接' }}</span
              ><span :style="{ background: serveColor }"></span> 设备: <span :style="{ color: deviceColor }">{{ deviceColor == 'red' ? '未连接' : '已连接' }}</span
              ><span :style="{ background: deviceColor }"></span>
            </div>
            <div>当前最新数据: {{ wsResult.result || '暂无' }} 时间: {{ nowTime }}</div>
            <div>
              实时日志:
              <div>
                <div v-for="item in logArr" :key="item.index">{{ item }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 院内本地 -->
      <div class="testBox" v-if="isEmpty">
        <div v-for="item in connectionStatus" :key="item.index">
          <div>{{ item.name }}</div>
          <div :style="{ background: item.flag }">
            <span>{{ item.value }}</span>
            <span style="font-size: 20px">{{ item.status }}</span>
          </div>
        </div>
      </div>
      <el-empty v-if="!isEmpty && !processFlag" description="暂无设备"></el-empty>
    </div>
  </div>
</template>
<script>
import { organizationQueryWithPage, deviceQueryWithPage, queryThreshold } from '@/util/http'
import { timesfun } from '@/util/timeData'

let charts = []
export default {
  data() {
    return {
      cgxqy: '', //区域
      cgxqyArr: JSON.parse(sessionStorage.getItem('gxqylist')), //区域数组
      cjgmc: '', //机构
      cjgmcArr: [], //机构数组
      csbmc: '', //设备
      csbmcArr: [], //设备数组
      optionCircle: {
        series: [
          {
            type: 'gauge',
            axisLine: {
              lineStyle: {
                width: 60,
                color: [
                  [0.3, '#07dc0c'],
                  [0.7, '#c7dc09'],
                  [1, '#dc0b00'],
                ],
              },
            },
            radius: '100%',
            center: ['50%', '55%'],
            min: 0,
            max: 2.5,
            pointer: {
              width: 20,
              length: '70%', //指针长度，按照半圆半径的百分比
              itemStyle: { color: 'auto' },
            },
            axisTick: {
              distance: -60,
              length: 25,
              lineStyle: { color: '#fff', width: 3 },
            },
            splitLine: {
              distance: -60,
              length: 40,
              lineStyle: { color: '#fff', width: 3 },
            },
            axisLabel: { color: 'inherit', distance: 100, fontSize: 28 },
            detail: {
              valueAnimation: false,
              formatter: '{value} μSv/h',
              color: 'inherit',
              offsetCenter: ['0%', '40%'],
              fontSize: 34,
            },
            data: [
              {
                value: 0,
                name: '实时数据',
                title: {
                  show: true,
                  offsetCenter: ['0%', '60%'],
                  color: 'inherit',
                  fontSize: 30,
                },
              },
            ],
          },
        ],
      },
      myChartCir: null, //图表仪表盘
      user: this.$store.state.user,
      user_area: this.$store.state.user.areaId, //区域显影
      user_org: this.$store.state.user.organizationId, //机构显影
      typeFlag: '', // 6手足表面污染, 17通道, 8中子,1固定式工作场所,2核素
      ws: {}, //websocket对象
      serveColor: 'red', // 服务器连接状态默认颜色
      deviceColor: 'red', // 设备连接状态默认颜色
      deviceName: '无',
      logArr: [],
      lineOption: {
        xAxis: {
          type: 'category',
          data: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59],
          axisLine: { lineStyle: { color: '#000' } },
          boundaryGap: false,
        },
        grid: {
          left: '3%',
          top: '3%',
          right: '5%',
          bottom: '2%',
          containLabel: true,
        },
        color: ['red', 'green'],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            //阴影背景
            type: 'shadow',
            shadowStyle: {
              color: 'rgba(150,150,150,0.3)',
              width: 'auto',
            },
          },
          backgroundColor: 'rgba(0,0,0,0.5)',
          color: '#fff',
          textStyle: { color: '#fff' },
        },
        yAxis: {
          type: 'value',
          axisTick: { show: true },
          nameTextStyle: { color: '#000' },
          axisLine: { show: true, lineStyle: { color: '#000' } },
          splitLine: {
            show: true,
            lineStyle: {
              color: 'rgba(255,255,255,0.2)',
            },
          },
        },
        series: [],
      },
      ctcq: [], //勾选探测器个数
      ctcqArr: [
        { id: 1, name: '1#γ探测器' },
        { id: 2, name: '2#γ探测器' },
        { id: 5, name: '3#γ探测器' },
        { id: 6, name: '4#γ探测器' },
        { id: 3, name: '1#中子探测器' },
        { id: 4, name: '2#中子探测器' },
      ],
      one: false,
      two: false,
      three: false,
      four: false,
      five: false,
      six: true,
      chatNum: [
        { id: 1, name: '1#γ探测器', probeThreshold: '', probe: '', probeMSThreshold: '', probeMS: '' },
        { id: 2, name: '2#γ探测器', probeThreshold: '', probe: '', probeMSThreshold: '', probeMS: '' },
        { id: 5, name: '3#γ探测器', probeThreshold: '', probe: '', probeMSThreshold: '', probeMS: '' },
        { id: 6, name: '4#γ探测器', probeThreshold: '', probe: '', probeMSThreshold: '', probeMS: '' },
        { id: 3, name: '1#中子探测器', probeThreshold: '', probe: '', probeMSThreshold: '', probeMS: '' },
        { id: 4, name: '2#中子探测器', probeThreshold: '', probe: '', probeMSThreshold: '', probeMS: '' },
      ], // 图表过滤
      echartDateArr: [], // 图表置空值
      chartNameNum: ['1#γ探测器', '2#γ探测器', '3#γ探测器', '4#γ探测器', '1#中子探测器', '2#中子探测器'], // 已勾选探测器个数
      chatNumNew: [], // 图表循环个数
      wsResult: {
        result: 0,
      },
      keepAliveTimer: null, // 心跳定时器
      reconnectTimeOutInterval: null, // 重连定时器
      lastTime: new Date(), // 最后一次连接时间
      connectNum: 1, // ws重连次数
      initialValue: true, // 初始图表值个数
      is_open_socket: false, //websocket连接是否打开,避免重复连接
      is_first_online: false, //是否第一次在线
      is_first_offline: false, //是否第一次离线
      is_active_close_socket: false, //是否主动关闭ws
      connectionStatus: [], // 连接
      isEmpty: false,
      thresholdArr: [],
      processFlag: false, // 区分线上服务器true和院内本地false
      baseUrl: '',
      nowTime: timesfun(new Date()),
    }
  },
  props: ['datas'],
  watch: {
    datas: {
      handler(newValue, oldValue) {
        this.typeFlag = newValue
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    // 重置
    async reserts() {
      this.clearData()
      this.connectNum = 1
      this.is_active_close_socket = true
      this.cgxqy = this.user.areaId
      if (this.cgxqy) await this.cxjgmc()
      this.cjgmc = this.user.organizationId
      if (this.cjgmc) {
        await this.cxsbmc()
      } else {
        this.csbmc = ''
        this.csbmcArr = []
      }
      if (this.user.loginname == 'admin') {
        this.adminDefault()
      }
      if (this.user.areaId && !this.user.organizationId) {
        this.cjgmc = this.cjgmcArr[0].id
        this.cxsbmc()
      }
    },
    // 查询机构名称
    async cxjgmc() {
      this.cjgmc = ''
      this.cjgmcArr = []
      this.csbmc = ''
      this.csbmcArr = []
      let params = {
        areaId: this.cgxqy,
        pageSize: 10000,
      }
      await organizationQueryWithPage(params).then((data) => {
        if (this.cgxqy) {
          this.cjgmcArr = data.result.records
        } else {
          this.cjgmcArr = []
        }
      })
    },
    // 查询设备名称
    cxsbmc() {
      if (!this.$has(this.typeFlag[1])) {
        this.$message.error('暂无权限！')
        return
      }
      // 院内本地就走
      if (!this.processFlag) {
        if (this.connectionStatus.length) {
          // 切换机构前关闭所有WebSocket连接
          this.connectionStatus.forEach((socket) => {
            socket.ws.close()
            socket.ws = null
            if (socket.setInt) {
              clearInterval(socket.setInt)
              socket.setInt = null
            }
            if (socket.setTim) {
              clearInterval(socket.setTim)
              socket.setTim = null
            }
            if (socket.reSetTim) {
              clearInterval(socket.reSetTim)
              socket.reSetTim = null
            }
            socket.flag = '#ccc'
            socket.retry = 5
            socket.value = ''
            socket.isActiveCloseSocket = true
          })
        }
      }
      this.csbmc = ''
      this.csbmcArr = []
      let params = {
        areaId: this.cgxqy,
        organizationId: this.cjgmc,
        type: this.typeFlag[0],
        pageSize: 10000,
      }
      deviceQueryWithPage(params).then((data) => {
        if (this.cjgmc) {
          this.csbmcArr = data.result.records
          // 是院内本地就走
          if (!this.processFlag) {
            this.connectionStatus = []
            this.connectionStatus = Object.assign([], data.result.records).map((v) => ({ id: v.id, name: v.name, value: '', flag: '#ccc', ws: null, retry: 5, setInt: null, status: '连接中...', isActiveCloseSocket: false, setTim: null, reSetTim: null }))
            this.connectionStatus.forEach((v) => {
              this.connect(v)
            })
            this.isEmpty = this.connectionStatus.length ? true : false
          }
        } else {
          this.csbmcArr = []
        }
        // 线上服务器就走
        if (this.processFlag) {
          if (this.csbmcArr.length) {
            this.clearData()
            this.connectNum = 1
            this.is_active_close_socket = true
            this.$nextTick(() => {
              this.csbmc = data.result.records[0].id
              this.createSocket(this.csbmc)
            })
          }
        }
      })
    },
    // 设备名称切换
    async cxchange() {
      if (this.csbmc) {
        await this.clearData()
        this.connectNum = 1
        this.is_active_close_socket = true
        this.createSocket(this.csbmc)
      }
    },
    // admin权限下的默认勾选
    async adminDefault() {
      this.cgxqy = this.cgxqyArr[0].id
      await this.cxjgmc()
      if (this.cjgmcArr.length) {
        this.cjgmc = this.cjgmcArr[0].id
        this.cxsbmc()
      }
    },
    // 探测器个数切换
    ctqchange() {
      this.chartNameNum = this.ctcq
      this.chatNumNew = this.chatNum.filter((v) => this.chartNameNum.some((k) => v.name == k))
      this.one = false
      this.two = false
      this.three = false
      this.four = false
      this.five = false
      this.six = false
      switch (this.chartNameNum.length) {
        case 1:
          this.one = true
          break
        case 2:
          this.two = true
          break
        case 3:
          this.three = true
          break
        case 4:
          this.four = true
          break
        case 5:
          this.five = true
          break
        case 6:
          this.six = true
          break
        default:
          break
      }
      this.$refs.selectTcq.blur()
    },
    // 图表置空
    clearChart() {
      this.echartDateArr = []
      let name = []
      if (this.typeFlag[0] == 18) {
        name = ['探测器1', '探测器2']
        for (var i = 0; i < name.length; i++) {
          // let data = [name[i], { data: [], type: 'line', name: '本底值' }, { data: [], type: 'line', name: '计数率' }, { data: [], type: 'line', name: '阈值' }]
          let data = [name[i], { data: [], type: 'line', name: '本底值' }, { data: [], type: 'line', name: '计数率' }, { data: [], type: 'line', name: '阈值' }]
          this.echartDateArr.push(data)
        }
      } else {
        name = ['1#γ探测器', '2#γ探测器', '3#γ探测器', '4#γ探测器', '1#中子探测器', '2#中子探测器']
        for (var i = 0; i < name.length; i++) {
          let data = [name[i], { data: [], type: 'line', name: '阈值' }, { data: [], type: 'line', name: '测量值' }]
          this.echartDateArr.push(data)
        }
      }
    },
    // 获取阈值
    getThreshold() {
      let params = {
        pageNo: 1,
        pageSize: 1000,
        deviceType: this.typeFlag[0],
      }
      queryThreshold(params).then((data) => {
        if (data) {
          let res = data.result.records
          this.thresholdArr = [res[0].code, res[1].code]
        }
      })
    },
    // 院内本地
    connect(socket) {
      const ws = new WebSocket(`${this.baseUrl}?deviceId=${socket.id}`)
      // const ws = new WebSocket('wss://radiation.supersense.cc/wss/ws?deviceId=' + socket.id)
      socket.ws = ws
      ws.onopen = () => {
        console.log(socket.id, 'WebSocket连接成功')
        // 连接成功，清除重连计数器，设置状态为已连接
        socket.retry = 5
        socket.status = '设备连接中...'
        // 发送心跳包
        socket.setInt = setInterval(() => {
          console.log('ping---------')
          ws.send(
            JSON.stringify({
              type: 'Ping',
              t: new Date().getTime(),
            })
          )
        }, 2000)
        socket.setTim = setTimeout(() => {
          if (socket.status == '设备连接中...') {
            socket.status = '设备连接失败'
          }
          console.log(socket.name)
        }, 1000 * 20)
      }
      ws.onmessage = (e) => {
        console.log(socket.id, `接收到消息：${JSON.parse(e.data).result}`)
        let id = e.currentTarget.url.split('=')[1]
        let res = this.connectionStatus.find((v) => v.id == id)
        if (res) {
          res.value = JSON.parse(e.data).result
          if (res.value < this.thresholdArr[0] || res.value > this.thresholdArr[1]) {
            res.flag = '#f00'
            window.speechSynthesis.speak(new SpeechSynthesisUtterance(`${res.name}辐射检测超标，请及时处理`))
          } else {
            res.flag = '#03cb05'
          }
          res.status = 'μSv/h'
        }
      }
      ws.onclose = () => {
        console.log(socket.id, 'WebSocket连接关闭')
        // 连接失败关闭心跳包定时器
        if (socket.setInt) {
          clearInterval(socket.setInt)
          socket.setInt = null
        }
        if (socket.setTim) {
          clearInterval(socket.setTim)
          socket.setTim = null
        }
        if (socket.reSetTim) {
          clearInterval(socket.reSetTim)
          socket.reSetTim = null
        }
        // 主动关闭,就直接关闭
        if (socket.isActiveCloseSocket) {
          ws.close()
        } else {
          // 被动关闭就重连
          socket.retry--
          console.log(socket.id, '第', 5 - socket.retry, '次重试------')
          socket.status = `第${5 - socket.retry}次重试`
          socket.value = ''
          socket.flag = '#ccc'
          // 尝试6次重连，如果还未连接成功则关闭连接
          if (socket.retry > 0) {
            socket.reSetTim = setTimeout(() => {
              this.connect(socket)
            }, 1000 * 5)
          } else {
            ws.close()
            socket.status = '服务器连接失败'
            console.log(socket.id, '已关闭=======')
          }
        }
        socket.isActiveCloseSocket = false
      }
    },
    // 建立连接
    createSocket(deviceId) {
      if ('WebSocket' in window) {
        // let wsUrl = `wss://${location.host}/wss/ws`
        // let wsUrl = 'ws://192.168.1.54:20000/ws'
        // wsUrl = 'ws://192.168.0.112:20000/ws'

        this.ws = new WebSocket(`${this.baseUrl}?deviceId=${deviceId}`)
        this.ws.onopen = this.websocketonopen
        this.ws.onerror = this.websocketonerror
        this.ws.onmessage = this.websocketonmessage
        this.ws.onclose = this.websocketclose
      } else {
        // 浏览器不支持 WebSocket
        this.$message.error('您的浏览器不支持 WebSocket!')
      }
    },
    // 连接成功后调用
    websocketonopen() {
      // this.$message.success('服务器连接成功!')
      this.logArr.unshift('服务器连接成功!')
      this.deviceName = this.csbmcArr.find((v) => v.id == this.csbmc).name
      this.serveColor = '#07dc0c'
      if (this.reconnectTimeOutInterval) {
        clearTimeout(this.reconnectTimeOutInterval)
        this.reconnectTimeOutInterval = null
      }
      if (this.keepAliveTimer) {
        clearTimeout(this.keepAliveTimer)
        this.keepAliveTimer = null
      }
      this.connectNum = 1
      this.is_open_socket = true
      this.is_first_online = true
      this.is_first_offline = true
      this.is_active_close_socket = false
      this.lastTime = new Date()
      // 心跳机制
      this.keepAlive()
    },
    // 发生错误时调用
    websocketonerror() {
      console.log('连接已出错...')
      this.is_active_close_socket = false
    },
    // 接收消息
    websocketonmessage(e) {
      // 防止切换设备数据推送上一个id
      // if (this.csbmc != e.currentTarget.url.split('=')[1]) return
      // console.log(e)
      if (this.is_first_online) {
        // this.$message.success('设备连接成功!')
        this.logArr.unshift('设备连接成功!')
        this.deviceColor = '#07dc0c'
        this.is_first_online = false
        if (this.reconnectTimeOutInterval) {
          clearTimeout(this.reconnectTimeOutInterval)
          this.reconnectTimeOutInterval = null
        }
        this.connectNum = 1
      }
      this.lastTime = new Date()
      if (this.typeFlag[0] == 17 || this.typeFlag[0] == 18) {
        var length = this.lineOption.xAxis.data.length
        if (this.typeFlag[0] == 17) {
          var data = JSON.parse(JSON.parse(e.data).result)
          this.chatNumNew.forEach((v, i) => {
            if (this.chartNameNum.some((item) => item == v.name)) {
              v.probeThreshold = data['probe' + v.id + 'CPSThreshold']
              v.probe = data['probe' + v.id + 'CPS']
              v.probeMSThreshold = data['probe' + v.id + 'ThresholdMs']
              v.probeMS = data['probe' + v.id + 'MS']
              var echartDateThreshold = this.echartDateArr[i][1].data
              var echartDate = this.echartDateArr[i][2].data
              echartDateThreshold.push(v.probeThreshold)
              echartDate.push(v.probe)
              if (echartDateThreshold.length == length + 1) echartDateThreshold.splice(0, 1)
              if (echartDate.length == length + 1) echartDate.splice(0, 1)
            }
          })
          if (this.initialValue) {
            // 过滤data全是0的数组,返回name
            let result = this.echartDateArr
              .filter((item) => {
                return item[1].data.every((val) => val != 0)
              })
              .map((item) => item[0])
            this.ctcq = result
            this.ctqchange()
            this.initialValue = false
          }
        } else {
          this.lineOption.color = ['green', 'orange', 'red']
          var data = JSON.parse(e.data).result
          this.chatNumNew.forEach((v, i) => {
            v.probeBackground = data['probe' + v.id + 'Background']
            v.probeCps = data['probe' + v.id + 'Cps']
            v.probeThreshold = data['probe' + v.id + 'Threshold']
            v.alarmTag = data.alarmTag
            v.pedNumber = data.pedestrianNum
            let probeBackgroundArr = this.echartDateArr[i][1].data
            let probeArr = this.echartDateArr[i][2].data
            let probeThresholdArr = this.echartDateArr[i][3].data
            probeBackgroundArr.push(v.probeBackground)
            probeArr.push(v.probeCps)
            probeThresholdArr.push(v.probeThreshold)
            if (probeBackgroundArr.length == length + 1) probeBackgroundArr.splice(0, 1)
            if (probeArr.length == length + 1) probeArr.splice(0, 1)
            if (probeThresholdArr.length == length + 1) probeThresholdArr.splice(0, 1)
          })
        }
        var echartsList = document.querySelectorAll('.contSty')
        charts = []
        for (var i = 0; i < echartsList.length; i++) {
          var myChart = this.$echarts.getInstanceByDom(echartsList[i])
          if (myChart == null) {
            myChart = this.$echarts.init(echartsList[i])
          }
          myChart.setOption(this.lineOption)
          charts.push(myChart)
          myChart.setOption({
            series: this.echartDateArr[i],
          })
        }
        for (var i = 0; i < charts.length; i++) {
          charts[i].resize()
        }
        window.addEventListener('resize', () => {
          for (var i = 0; i < charts.length; i++) {
            charts[i].resize()
          }
        })
      } else {
        this.wsResult = JSON.parse(e.data)
        let rsVal = this.wsResult.result
        this.logArr.unshift('接收到数据: ' + rsVal + ' 时间: ' + timesfun(new Date()))
        if (rsVal < this.thresholdArr[0] || rsVal > this.thresholdArr[1]) {
          window.speechSynthesis.speak(new SpeechSynthesisUtterance(`${this.deviceName}辐射检测超标，请及时处理`))
        }
        this.optionCircle.series[0].data[0].value = rsVal
        this.myChartCir.setOption(this.optionCircle)
      }
    },
    // 关闭连接时调用
    websocketclose() {
      console.log('关闭连接')
      if (this.is_active_close_socket) {
        this.logArr.unshift(this.deviceName + '已关闭连接')
      }
      if (this.reconnectTimeOutInterval) {
        clearTimeout(this.reconnectTimeOutInterval)
        this.reconnectTimeOutInterval = null
      }
      if (this.keepAliveTimer) {
        clearTimeout(this.keepAliveTimer)
        this.keepAliveTimer = null
      }
      this.is_open_socket = false
      if (this.connectNum < 6) {
        this.serveColor = 'red'
        this.deviceColor = 'red'
        if (!this.is_active_close_socket) {
          this.$message.error(`服务器连接失败，正尝试第${this.connectNum}次连接`)
          this.logArr.unshift(`服务器连接失败，正尝试第${this.connectNum}次连接`)
          this.reconnectTimeOutInterval = setTimeout(() => {
            this.createSocket(this.csbmc)
          }, 5000)
          this.connectNum += 1
        }
      } else {
        if (!this.is_active_close_socket) {
          this.$message.error('服务器连接失败，请刷新页面重试')
          this.logArr.unshift('服务器连接失败，请刷新页面重试')
        }
        this.connectNum = 1
      }
      this.is_active_close_socket = false
    },
    // 心跳机制
    keepAlive() {
      console.log(this.csbmc, '心跳', new Date())
      // this.logArr.unshift('接收到数据: ' + this.wsResult.result + ' 时间: ' + timesfun(new Date()))
      if (new Date().getTime() - this.lastTime.getTime() > 1000 * 20) {
        if (this.is_first_offline) {
          this.$message.error('设备连接失败!')
          this.logArr.unshift('设备连接失败!')
        }
        this.is_first_offline = false
        this.is_first_online = true
      } else {
        if (this.is_first_online && this.is_first_offline) {
          this.logArr.unshift('设备连接中...')
        }
      }
      if (this.keepAliveTimer) {
        clearTimeout(this.keepAliveTimer)
        this.keepAliveTimer = null
      }
      this.keepAliveTimer = setTimeout(() => {
        //判断当前webscokt状态
        if (this.ws.readyState) {
          //调用发送方法
          this.ws.send(
            JSON.stringify({
              type: 'Ping',
              t: new Date().getTime(),
            })
          )
          this.keepAlive()
        }
      }, 2000)
    },
    // 清空数据连接
    clearData() {
      if (this.ws.readyState) {
        this.ws.close()
      }
      this.ws = {}
      this.serveColor = 'red'
      this.deviceColor = 'red'
      this.wsResult = {
        result: 0,
      }
      if (this.typeFlag[0] == 17 || this.typeFlag[0] == 18) {
        this.clearChart()
        var echartsList = document.querySelectorAll('.contSty')
        charts = []
        for (var i = 0; i < echartsList.length; i++) {
          var myChart = this.$echarts.getInstanceByDom(echartsList[i])
          if (myChart == null) {
            myChart = this.$echarts.init(echartsList[i])
          }
          myChart.setOption(this.lineOption)
          myChart.setOption({
            series: this.echartDateArr[i],
          })
        }
      } else {
        this.optionCircle.series[0].data[0].value = 0
        this.myChartCir.setOption(this.optionCircle)
      }
      if (this.keepAliveTimer) {
        clearTimeout(this.keepAliveTimer)
        this.keepAliveTimer = null
      }
      if (this.reconnectTimeOutInterval) {
        clearTimeout(this.reconnectTimeOutInterval)
        this.reconnectTimeOutInterval = null
      }
    },
    // 初始化图表
    initChart() {
      this.myChartCir = this.$echarts.getInstanceByDom(this.$refs.emainCir)
      if (this.myChartCir == null) {
        this.myChartCir = this.$echarts.init(this.$refs.emainCir)
      }
      this.optionCircle.series[0].data[0].value = 0
      this.myChartCir.setOption(this.optionCircle)
      window.addEventListener('resize', () => {
        this.myChartCir.resize()
      })
    },
  },
  async mounted() {
    var regExpDomain = /^[a-zA-Z0-9][a-zA-Z0-9\.\-]*\.[a-zA-Z]{2,4}$/
    this.processFlag = regExpDomain.test(window.location.host)
    // true 线上, false 本地
    this.getThreshold()
    if (this.processFlag) {
      this.baseUrl = `wss://${location.host}/wss/ws`
    } else {
      this.baseUrl = `ws://${location.hostname}:20000/ws`
    }
    if (this.typeFlag[0] != 1) this.processFlag = true
    // 线上服务器就走
    if (this.processFlag) {
      if (this.typeFlag[0] == 17) {
        this.chatNumNew = this.chatNum
        this.clearChart()
      } else if (this.typeFlag[0] == 18) {
        this.chatNumNew = [
          { id: 1, name: '探测器1', probeBackground: '', probeCps: '', probeThreshold: '', alarmTag: '', pedNumber: '' },
          { id: 2, name: '探测器2', probeBackground: '', probeCps: '', probeThreshold: '', alarmTag: '', pedNumber: '' },
        ]
        this.clearChart()
      } else {
        this.$nextTick(() => {
          this.initChart()
        })
      }
    }
    this.cgxqy = this.user.areaId
    if (this.cgxqy) await this.cxjgmc()
    this.cjgmc = this.user.organizationId
    if (this.cjgmc) await this.cxsbmc()
    // admin
    if (!this.user.areaId || !this.user.organizationId) {
      this.adminDefault()
    }
    // 区域
    if (this.user.areaId && !this.user.organizationId) {
      this.cjgmc = this.cjgmcArr[0].id
      this.cxsbmc()
    }
  },
  beforeDestroy() {
    // 关闭所有WebSocket连接
    this.connectionStatus.forEach((socket) => {
      socket.ws.close()
      if (socket.setInt) {
        clearInterval(socket.setInt)
        socket.setInt = null
      }
      if (socket.setTim) {
        clearInterval(socket.setTim)
        socket.setTim = null
      }
      if (socket.reSetTim) {
        clearInterval(socket.reSetTim)
        socket.reSetTim = null
      }
      socket.isActiveCloseSocket = true
    })
    // 线上服务器就走
    if (this.processFlag) {
      // 关闭连接
      this.clearData()
      this.connectNum = 1
      this.is_open_socket = false
      this.is_active_close_socket = true
    }
  },
}
</script>
<style src="../../../public/css/search.less" lang="less" scoped></style>
<style lang="less" scoped>
.about {
  padding: 0;
  height: 100%;
  overflow: hidden;
}
.azjgs .el-select {
  margin: 0;
}
// 上半部分
.topcont {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  .topright {
    display: flex;
    align-items: center;
    padding-left: 10px;
  }
  .topbottom {
    height: calc(100% - 36px);
    display: flex;
    font-size: initial;
  }
  #emainCir {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
  }
}
// .devSty {
//   /deep/.el-input--suffix {
//     min-width: 210px;
//   }
// }
.chartData {
  display: flex;
  width: 100%;
  > :first-child {
    width: 78%;
    box-sizing: border-box;
  }
  > :last-child {
    width: 22%;
    border: 1px solid #ccc;
    font-size: initial;
    height: 94%;
    .statusSty {
      span {
        color: red;
      }
      > :last-child,
      > :nth-child(2) {
        display: inline-block;
        width: 20px;
        background: red;
        height: 20px;
        border-radius: 10px;
        vertical-align: bottom;
        margin-left: 5px;
      }
    }
    > :last-child {
      height: calc(100% - 63px);
      overflow: auto;
      div {
        :nth-child(2n-1) {
          background: #eee;
        }
      }
    }
  }
}
// 图表个数
// 一个
.one {
  width: 100%;
  height: 100%;
  border: 1px solid #ccc;
  box-sizing: border-box;
  > div {
    height: 100%;
    .contSty {
      width: 100%;
      height: 95%;
    }
  }
}
// 两个
.two {
  width: 100%;
  height: 100%;
  border: 1px solid #ccc;
  box-sizing: border-box;
  > div {
    height: 50%;
    .contSty {
      width: 100%;
      height: 95%;
    }
  }
  > :first-child {
    border-bottom: 1px solid #ccc;
  }
}
// 3个
.three {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  border: 1px solid #ccc;
  box-sizing: border-box;
  > div {
    height: 50%;
    width: 50%;
    box-sizing: border-box;
    .contSty {
      width: 100%;
      height: 95%;
    }
  }
  > :first-child {
    width: 100%;
    border-bottom: 1px solid #ccc;
  }
  > :last-child {
    border-left: 1px solid #ccc;
  }
}
// 四个
.four {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  border: 1px solid #ccc;
  box-sizing: border-box;
  > div {
    height: 50%;
    width: 50%;
    box-sizing: border-box;
    .contSty {
      width: 100%;
      height: 95%;
    }
  }
  > :first-child {
    border-bottom: 1px solid #ccc;
  }
  > :nth-child(2) {
    border-left: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
  }
  > :last-child {
    border-left: 1px solid #ccc;
  }
}
// 5个
.five {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  border: 1px solid #ccc;
  box-sizing: border-box;
  > div {
    width: calc(100% / 3);
    height: 50%;
    box-sizing: border-box;
    .contSty {
      width: 100%;
      height: 95%;
    }
  }
  > :first-child {
    border-right: 1px solid #ccc;
  }
  > :first-child,
  > :nth-child(2) {
    width: 50%;
    border-bottom: 1px solid #ccc;
  }
  > :nth-last-child(2) {
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
  }
}
// 六个
.six {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  border: 1px solid #ccc;
  box-sizing: border-box;
  > div {
    width: calc(100% / 3);
    height: 50%;
    box-sizing: border-box;
    .contSty {
      width: 100%;
      height: 95%;
    }
  }
  > :first-child,
  > :nth-child(3) {
    border-bottom: 1px solid #ccc;
  }
  > :nth-child(2) {
    border-left: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    border-right: 1px solid #ccc;
  }

  > :nth-child(5) {
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
  }
}
.testBox {
  width: 100%;
  height: calc(100% - 10px - 38px);
  margin-top: 10px;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  overflow: auto;
  > div {
    width: calc((100% - 36px) / 6);
    height: 140px;
    margin: 3px;
    border: 1px solid #ccc;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 10px;
    overflow: hidden;
    > :first-child {
      font-size: initial;
      text-align: center;
      height: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    > :last-child {
      height: 50%;
      background: #ccc;
      color: #fff;
      font-size: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
// 行人通道
.twoSty {
  width: 100%;
  height: 100%;
  border: 1px solid #ccc;
  box-sizing: border-box;
  > div {
    height: calc(50% - 10px);
    padding: 5px;
    .contSty {
      width: 100%;
      height: calc(100% - 21px);
    }
  }
  > :first-child {
    border-bottom: 1px solid #ccc;
  }
}
@media screen and (max-width: 1000px) {
  .topcont .topright {
    flex-direction: column;
  }
  .titles > span {
    top: 208px;
    width: calc(100% - 40px);
  }
  .flag17 {
    height: calc(100% - 149px) !important;
    > div {
      div {
        width: 100%;
      }
    }
  }
}
</style>
